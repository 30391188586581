class UserAddress {
    constructor(data) {
        for (const i in data) {
            this[i] = data[i];
        }
    }

    toString() {
        return [this.getCityTitle(), this.street, this.house, this.building]
            .filter((x) => {
                return !!x;
            })
            .join(", ");
    }

    getComment() {
        if (this.flat === "ч/д") {
            return this.comment ? this.comment : [];
        }
        return [
            this.entrance ? "под. " + this.entrance : this.entrance,
            this.floor ? "эт. " + this.floor : this.floor,
            this.flat ? "кв. " + this.flat : this.flat,
            this.domofon ? "домофон " + this.domofon : "",
            this.comment ? this.comment : "",
        ]
            .filter((x) => {
                return !!x;
            })
            .join(", ");
    }

    getCityTitle() {
        if (this.sub_city) {
            return this.sub_city;
        }
        return this.city;
    }
}

export default UserAddress;
