export const KITCHEN = {
    JAPAN: 1,
    PIZZA: 2,
};

export const DELIVERY_TYPE = {
    SELF: "self",
    SELF_CAR: "self_car",
    DELIVERY: "delivery",
    HALL: "hall",
    GIFT_SERTIFICATE: "gift_certificate",
};

export const DELIVERY_TIME = {
    CURRENT: "quick_prepare",
    SPECIFIC: "specific_time",
};

export const ORDER_STEPS = {
    ORDER: "products",
    DETAILS: "details",
    FINISH: "finish",
};

export const ACCOUNT_TABS = {
    CURRENT_ORDERS: "current_orders",
    ORDERS_HISTORY: "orders-history",
    PROFILE: "profile",
    BONUS_PROGRAM: "bonus-program",
};
export const RECOVERY_REGISTATION_TABS = {
    LOGIN: "login",
    RECOVERY: "recovery",
    REGISTER: "register",
};
export const ORDER_PRODUCT_SCORES_VALUES = {
    BAD: 0,
    MEDIUM: 5,
    PERFECT: 10,
};

export const ORDER_PRODUCT_SCORES = {
    [ORDER_PRODUCT_SCORES_VALUES.BAD]: "bad",
    [ORDER_PRODUCT_SCORES_VALUES.MEDIUM]: "medium",
    [ORDER_PRODUCT_SCORES_VALUES.PERFECT]: "perfect",
};

export const ORDER_STEPS_INDEX = [ORDER_STEPS.ORDER, ORDER_STEPS.DETAILS, ORDER_STEPS.FINISH];

export const getStepIndex = (step) => {
    return ORDER_STEPS_INDEX.indexOf(step);
};

export const getNextStep = (step) => {
    return ORDER_STEPS_INDEX[getStepIndex(step) + 1];
};

export const getPrevStep = (step) => {
    return ORDER_STEPS_INDEX[getStepIndex(step) - 1];
};

export const validateStep = (step) => {
    return step && ORDER_STEPS_INDEX.includes(step) ? step : ORDER_STEPS.ORDER;
};

export const CITY_ID_EKB = 1;

export const DEFAULT_CITY_ID = CITY_ID_EKB;
export const DEFAULT_DEPARTMENT_ID = 0;
export const DEFAULT_DELIVERY_TYPE = DELIVERY_TYPE.DELIVERY;
export const DEFAULT_CLOSEST_DEPARTMENTS = "";

export const STOP_LIST_MODE_CHECK = "check";
export const STOP_LIST_MODE_FAIL = "fail";
export const STOP_LIST_MODE_REMOVE = "remove";
