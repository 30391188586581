import { render, staticRenderFns } from "./RecommendedProduct.vue?vue&type=template&id=9b1bb0f4&scoped=true&"
import script from "./RecommendedProduct.vue?vue&type=script&lang=js&"
export * from "./RecommendedProduct.vue?vue&type=script&lang=js&"
import style0 from "./RecommendedProduct.vue?vue&type=style&index=0&id=9b1bb0f4&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9b1bb0f4",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ProductScore: require('/usr/src/app/components/products/ProductScore.vue').default,RecommendedProductAction: require('/usr/src/app/components/products/RecommendedProductAction.vue').default})
