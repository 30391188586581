import { render, staticRenderFns } from "./AuthBlock.vue?vue&type=template&id=3b11c79c&scoped=true&"
import script from "./AuthBlock.vue?vue&type=script&lang=js&"
export * from "./AuthBlock.vue?vue&type=script&lang=js&"
import style0 from "./AuthBlock.vue?vue&type=style&index=0&id=3b11c79c&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3b11c79c",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ExternalPhoneConfirm: require('/usr/src/app/components/common/ExternalPhoneConfirm.vue').default,UserIcon: require('/usr/src/app/components/icons/UserIcon.vue').default,Auth: require('/usr/src/app/components/icons/Auth.vue').default,NuxtCityLink: require('/usr/src/app/components/common/NuxtCityLink.vue').default,LogoutIcon: require('/usr/src/app/components/icons/LogoutIcon.vue').default})
