//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: "Toggler",
    props: {
        type: {
            type: String,
            default: "switch",
        },
        updateOnMount: {
            type: Boolean,
            default: false,
        },
        firstButtonLabel: { type: String },
        secondButtonLabel: { type: String },
        firstButtonHandler: {
            type: Function,
            required: true,
        },
        secondButtonHandler: {
            type: Function,
            required: true,
        },
        activeElement: {
            // 'first' / 'second'
            type: String,
            required: true,
        },
    },
    computed: {
        isFirstItem() {
            return this.activeElement == "first";
        },
    },
};
