import Vue from "vue";
import { ActionTree, GetterTree, MutationTree } from "vuex";
import { ToastsState, Toast } from "~/store_types/toasts.types";
import { RootState } from "~/store_types/index.types";

class TheToast implements Toast {
    private static idCounter = 0;

    id: string;

    constructor(public message: string, public type: string = "info", public timeout: number = 5000) {
        this.id = `toast-${TheToast.idCounter++}`;
        this.message = message;
        this.type = type;
        this.timeout = timeout;
    }
}

// initial state
export const state: () => ToastsState = () => ({
    toasts: [],
});

// getters
export const getters: GetterTree<ToastsState, RootState> = {
    getToasts(state) {
        return state.toasts;
    },
};

// actions
export const actions: ActionTree<ToastsState, RootState> = {
    addToast({ commit }, { message, type, timeout }) {
        const toast = new TheToast(message, type, timeout);
        commit("add", toast);
        setTimeout(() => {
            commit("remove", toast.id);
        }, toast.timeout);
    },
    removeToast({ commit }, toastId) {
        commit("remove", toastId);
    },
};

// mutations
export const mutations: MutationTree<ToastsState> = {
    add(state, toast) {
        state.toasts.push(toast);
    },
    remove(state, toastId) {
        state.toasts = state.toasts.filter((toast) => toast.id !== toastId);
    },
};
