//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import CircleLoader from "~/components/CircleLoader.vue";

export default {
    name: "AllCustomButtons",
    components: { CircleLoader },
    emits: ["clickDetected"],
    props: {
        buttonType: {
            // primary | (bordered, bordered.color) | blank
            type: String,
            default: "primary",
        },
        styles: {
            // инлайн стили (можно просто навесить класс на компонент)
            type: Object,
        },
        disabledWhen: {
            // условие блокировки кнопки
            type: Boolean,
            default: false,
        },
        needPropagation: {
            // если false вызовется stopPropagation
            type: Boolean,
            default: true,
        },
        needDefaultAction: {
            // если false вызовется preventDefault
            type: Boolean,
            default: false,
        },
        getEvent: {
            // Передача ивэнта в emit
            type: Boolean,
            default: false,
        },
        nativeType: { type: String },
        isLoading: {
            type: Boolean,
            default: false,
        },
        loadingType: {
            type: String,
            default: "gradient",
        },
        loadingLabel: {
            type: String,
            default: "",
        },
        disableDuringLoading: {
            type: Boolean,
            default: true,
        },
    },
    methods: {
        clickDetection(evt) {
            if (!this.needPropagation) {
                evt.stopPropagation();
            }
            if (!this.needDefaultAction) {
                evt.preventDefault();
            }
            let parsedEvent;
            if (this.getEvent) {
                parsedEvent = evt;
            }
            this.$emit("clickDetected", parsedEvent);
        },
    },
};
