//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from "vuex";
import TooltipArrow from "~/components/icons/TooltipArrow.vue";

export default {
    name: "SmallInfoModal",
    components: { TooltipArrow },
    props: {
        startPosition: {
            type: String,
        },
        trigger: {},
        arrowPosition: {
            type: String,
        },
        gap: {
            type: Number,
            default: 10,
        },
        closeEvents: {
            type: Array,
        },
        modalStyle: {
            type: String,
        },
        noTriggerClose: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            modal: null,
            modalMargin: 8,
            coords: { x: "", y: "" },
            arrowCoords: { x: "", y: "" },
            modalHeight: 0,
            fade: false,
            helpers: {
                coords: { x: "", y: "" },
                helpCoord: { x: "", y: "" },
                extraCoord: { x: "", y: "" },
            },
            arrow: {
                width: 8,
                height: 18,
            },
            eventHandlers: null,
            eventActive: false,
        };
    },
    computed: {
        styleClass() {
            return this.modalStyle ? `small-modal_${this.modalStyle}` : "small-modal_default";
        },
        screenWidth() {
            return this.$screen.width;
        },
        arrowClass() {
            switch (this.startPosition) {
                case "top-left":
                case "top-right":
                case "top-center":
                case "bottom-left":
                case "bottom-center":
                    return {
                        desctop: true,
                        mobile: false,
                    };
                default:
                    return {
                        desctop: this.screenWidth < 961,
                        mobile: this.screenWidth > 960,
                    };
            }
        },
        // Отступ тела от триггера
        modalGap() {
            return this.gap + this.arrow.width;
        },
    },
    mounted() {
        if (!this.trigger) {
            return;
        }
        this.updateCoords();
        this.setExtraCoord(this.trigger.getBoundingClientRect().left);
        this.triggerHeight = this.trigger.getBoundingClientRect().height;
        this.modal = this.$refs.smallInfoModal;
        this.modalHeight = this.$refs.smallInfoModal.getBoundingClientRect().height;
        this.modalWidth = this.$refs.smallInfoModal.getBoundingClientRect().width;
        this.setCustomCoords();
        this.setCustomArrowCoord();
        this.fade = true;
        setTimeout(() => {
            this.initCloseEvents();
        }, 300);
    },
    beforeDestroy() {
        this.removeCloseEvents();
        this.setDefaultData();
        this.fade = false;
    },
    watch: {
        eventActive(nv) {
            if (nv) {
                this.removeCloseEvents();
            }
        },
    },
    methods: {
        initCloseEvents() {
            function getScrollParent(node) {
                const isScrollable = (node) => /^(auto|scroll)$/.test(window.getComputedStyle(node)["overflow-y"]);
                while (node && node !== document.body && !isScrollable(node)) {
                    node = node.parentElement; // Движемся к родительскому элементу!
                }
                if (node === document.body) {
                    node = document;
                }
                return node || document; // Возвращаем родителя с прокруткой или, при его отсутствии, document.body.
            }
            this.eventHandlers = {
                scroll: {
                    eventName: ["scroll"],
                    eventTrigger: getScrollParent(this.trigger),
                    event: () => {
                        this.hide("scroll");
                    },
                },
                click: {
                    eventName: ["click", "touchstart"],
                    eventTrigger: this.modal,
                    event: (evt) => {
                        evt.preventDefault();
                        if (this.modal && !this.eventActive) {
                            if (this.modal.contains(evt.target)) {
                                this.hide("click");
                            }
                        }
                    },
                },
                "click-outside": {
                    eventName: ["click", "touchstart"],
                    eventTrigger: document,
                    event: (evt) => {
                        evt.preventDefault();
                        if (this.modal && this.trigger && !this.eventActive) {
                            const noTriggerContains = this.noTriggerClose
                                ? true
                                : !this.trigger.contains(evt.target);
                            if (!this.modal.contains(evt.target) && noTriggerContains) {
                                this.hide("click-outside");
                            }
                        }
                    },
                },
            };

            if (this.closeEvents) {
                this.closeEvents.forEach((eventName) => {
                    const handler = this.eventHandlers[eventName];

                    if (handler && handler.eventTrigger && handler.eventName && handler.event) {
                        handler.eventName.forEach((el) => {
                            const options = el.startsWith("touch") ? { passive: false } : false;
                            handler.eventTrigger.addEventListener(el, handler.event, options);
                        });
                    }
                });
            }
        },
        removeCloseEvents() {
            if (this.closeEvents && this.eventHandlers) {
                this.closeEvents.forEach((eventName) => {
                    const handler = this.eventHandlers[eventName];
                    if (handler && handler.eventTrigger && handler.eventName && handler.event) {
                        handler.eventName.forEach((el) => {
                            const options = el.startsWith("touch") ? { passive: false } : false;
                            handler.eventTrigger.removeEventListener(el, handler.event, options);
                        });
                    }
                });
            }
        },
        hide(e) {
            this.eventActive = true;
            this.$emit("hideModal");
        },
        updateCoords() {
            const coordinates = this.trigger.getBoundingClientRect();

            const helpCoordinates = this.$refs.smallInfoModal.parentElement.getBoundingClientRect();
            this.setCoords(this.helpers.coords, coordinates.left, coordinates.top);
            this.setCoords(this.helpers.helpCoord, helpCoordinates.left, helpCoordinates.top);
        },
        setCoords(coordType, coordX, coordY) {
            coordType.x = Math.round(coordX);
            coordType.y = Math.round(coordY);
        },
        setExtraCoord(extraCoord) {
            this.helpers.extraCoord = Math.round(extraCoord);
        },

        // Стандартное расположение стрелки по y
        setArrowTopDown() {
            this.arrowCoords.y = this.helpers.coords.y - this.modalMargin - this.gap + "px";

            if (this.modalHeight + this.modalMargin + 100 > this.helpers.coords.y) {
                this.arrowCoords.y = this.helpers.coords.y + this.triggerHeight + this.gap + "px";
                this.$refs.arrow.style.transform = "scale(-1, -1)";
            }
        },
        // Стандартное расположение тела по y
        setModalTopDown() {
            this.coords.y = this.helpers.coords.y - this.modalHeight - this.modalGap + "px";

            if (this.modalHeight + this.modalMargin + 100 > this.helpers.coords.y) {
                this.coords.y = this.helpers.coords.y + this.triggerHeight + this.modalGap + "px";
            }
        },

        // Кастомное расположение тела по y
        checkModalY() {
            switch (this.startPosition) {
                case "bottom-left":
                case "bottom-center":
                    this.coords.y = this.helpers.coords.y + this.triggerHeight + this.modalGap + "px";
                    break;
                case "top-left":
                case "top-right":
                case "top-center":
                    this.setModalTopDown();
                    break;
                default:
                    if (this.screenWidth < 961) {
                        this.coords.y = this.helpers.coords.y - this.modalHeight - this.modalGap + "px";

                        if (this.modalHeight + this.modalMargin > this.helpers.coords.y) {
                            this.coords.y = this.helpers.coords.y + this.triggerHeight + this.modalGap + "px";
                        }
                    } else {
                        this.coords.y = this.helpers.coords.y - this.modalHeight / 4 + "px";

                        const windowHeight = window.innerHeight || document.documentElement.clientHeight;
                        let rez = windowHeight - 16 - (this.helpers.coords.y + this.modalHeight);

                        rez = rez < 0 ? rez - this.modalMargin : 0;

                        // Проверка, не выходит ли модальное окно за нижнюю границу окна
                        if (rez < 0) {
                            // Если модальное окно не помещается снизу, устанавливаем его выше триггера
                            this.coords.y = this.helpers.coords.y + rez + "px";
                        }
                    }
                    break;
            }
        },

        getOverflow() {
            return {
                left: Math.min(
                    0,
                    +this.helpers.coords.x - 16 - this.modalWidth + this.arrow.height + this.modalMargin
                ),
                right: Math.min(0, this.screenWidth - (this.helpers.coords.x + this.modalWidth) - 16),
            };
        },

        checkModalX() {
            const rez = this.getOverflow();

            const widthRez = this.trigger.getBoundingClientRect().width - this.modalWidth;

            switch (this.startPosition) {
                case "top-center":
                case "bottom-center":
                    this.coords.x = this.helpers.coords.x + widthRez / 2 + rez.right + "px";
                    break;
                case "top-left":
                case "bottom-left":
                    this.coords.x = this.helpers.coords.x + rez.right + "px";
                    if (this.screenWidth <= 480 && this.modalWidth > this.$screen.width * 0.8) {
                        this.coords.x = 14 + "px";
                    }
                    break;
                case "top-right":
                    this.coords.x =
                        this.helpers.coords.x -
                        this.modalWidth +
                        this.arrow.height +
                        this.modalMargin -
                        rez.left +
                        "px";
                    break;
                default:
                    if (this.screenWidth <= 480) {
                        this.coords.x = 14 + "px";
                    } else if (this.screenWidth < 961 && this.screenWidth > 480) {
                        this.coords.x = this.helpers.coords.x + rez.right + "px";
                    } else {
                        this.coords.x = this.helpers.coords.x - this.modalWidth - this.modalGap + "px";
                    }
                    break;
            }
        },

        // Кастомное расположение стрелки по y
        checkArrowY() {
            switch (this.startPosition) {
                case "bottom-left":
                case "bottom-center":
                    this.arrowCoords.y = this.helpers.coords.y + this.triggerHeight + this.gap + "px";
                    this.$refs.arrow.style.transform = "scale(-1, -1)";
                    break;
                case "top-left":
                case "top-right":
                case "top-center":
                    this.setArrowTopDown();
                    break;
                default:
                    if (this.screenWidth < 961) {
                        this.arrowCoords.y = this.helpers.coords.y - this.modalMargin - this.gap + "px";

                        if (this.modalHeight + this.modalMargin > this.helpers.coords.y) {
                            this.arrowCoords.y = this.helpers.coords.y + this.triggerHeight + this.gap + "px";
                            this.$refs.arrow.style.transform = "scale(-1, -1)";
                        }
                    } else {
                        this.arrowCoords.y = this.helpers.coords.y + "px";
                    }
                    break;
            }

            if (this.arrowPosition) {
                // Относительно триггер
                switch (this.arrowPosition) {
                    case "vertical-center":
                        this.arrowCoords.y =
                            this.helpers.coords.y + this.triggerHeight / 2 - this.arrow.width + "px";

                        if (this.screenWidth < 961) {
                            this.arrowCoords.y = this.helpers.coords.y - this.modalMargin - this.gap + "px";
                            if (this.modalHeight + this.modalMargin > this.helpers.coords.y) {
                                this.arrowCoords.y = this.helpers.coords.y + this.triggerHeight + this.gap + "px";
                                this.$refs.arrow.style.transform = "scale(-1, -1)";
                            }
                        }
                        break;
                }
            }
        },

        checkArrowX() {
            switch (this.startPosition) {
                case "top-left":
                case "bottom-left":
                case "bottom-center":
                case "top-center":
                    this.arrowCoords.x = this.helpers.coords.x + this.arrow.width + "px";
                    break;
                case "top-right":
                    this.arrowCoords.x = this.helpers.coords.x + "px";
                    break;
                default:
                    if (this.screenWidth <= 480) {
                        if (this.helpers.extraCoord) {
                            this.arrowCoords.x = this.helpers.extraCoord + 15 + "px";
                        } else {
                            this.arrowCoords.x = this.helpers.coords.x + 30 + "px";
                        }
                    } else if (this.screenWidth < 961 && this.screenWidth > 480) {
                        this.arrowCoords.x = this.helpers.coords.x + 15 + "px";
                    } else {
                        this.arrowCoords.x = this.helpers.coords.x - this.arrow.width + 2 - this.modalGap + "px";
                    }
                    break;
            }

            if (this.arrowPosition) {
                // Относительно триггер
                switch (this.arrowPosition) {
                    case "center":
                        this.arrowCoords.x =
                            this.helpers.coords.x +
                            this.trigger.getBoundingClientRect().width / 2 -
                            this.arrow.width +
                            "px";
                        break;
                }
            }
        },

        // Установка положение тела
        setCustomCoords() {
            this.checkModalY();
            this.checkModalX();
        },

        // Установка положение стрелки
        setCustomArrowCoord() {
            this.checkArrowY();
            this.checkArrowX();
        },

        setDefaultData() {
            this.coords.x = "";
            this.coords.y = "";
            this.arrowCoords.x = "";
            this.arrowCoords.y = "";
            this.modalHeight = 0;
        },
        getCoords(axis, el) {
            return this[el][axis];
        },
    },
};
