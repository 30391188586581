//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import DefaultLayoutMixin from "../mixins/DefaultLayoutMixin";

export default { mixins: [DefaultLayoutMixin] };
