import { render, staticRenderFns } from "./ProductActions.vue?vue&type=template&id=5647fe9c&scoped=true&"
import script from "./ProductActions.vue?vue&type=script&lang=js&"
export * from "./ProductActions.vue?vue&type=script&lang=js&"
import style0 from "./ProductActions.vue?vue&type=style&index=0&id=5647fe9c&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5647fe9c",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AddressMapIcon: require('/usr/src/app/components/icons/AddressMapIcon.vue').default,ProductBeforeDiscountPrice: require('/usr/src/app/components/products/ProductBeforeDiscountPrice.vue').default,AllCustomButtons: require('/usr/src/app/components/common/AllCustomButtons.vue').default,Minus: require('/usr/src/app/components/icons/Minus.vue').default,Plus: require('/usr/src/app/components/icons/Plus.vue').default,Trash: require('/usr/src/app/components/icons/Trash.vue').default})
