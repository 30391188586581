//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from "vuex";
import TickCircle from "~/components/icons/TickCircle.vue";
import InfoStrong from "~/components/icons/InfoStrong.vue";
import AttentionIcon from "~/components/icons/AttentionIcon.vue";

export default {
    name: "Toasts",
    components: { AttentionIcon, InfoStrong, TickCircle },
    computed: {
        ...mapGetters("toasts", { toasts: "getToasts" }),
    },
};
