//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters } from "vuex";
import LogoMobile from "~/components/icons/LogoMobile.vue";
import CrossSmall from "~/components/icons/CrossSmall.vue";
import { sendYandexMetric } from "~/service/ecommerce";
import config from "~/config";

export default {
    name: "MobileAppBanner",
    components: { CrossSmall, LogoMobile },
    data: () => ({}),
    computed: {
        ...mapGetters("modals", ["anyOpenModals"]),
        getLink() {
            if (this.iOS) {
                return config.APP_LINK_IOS;
            }

            if (this.isAndroid) {
                return config.APP_LINK_ANDROID;
            }

            return "";
        },
        iOS() {
            return this.$device.isIos;
        },
        isAndroid() {
            return this.$device.isAndroid;
        },
    },
    methods: {
        ...mapActions("account", ["setAppBanner"]),
        closeBanner() {
            sendYandexMetric("close-banner-heder-app");
            this.setAppBanner(false);
        },
        redirect() {
            if (process.browser) {
                window.location.href = this.getLink;
            }
        },
    },
};
