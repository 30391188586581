import { render, staticRenderFns } from "./default.vue?vue&type=template&id=5a04c39a&scoped=true&"
import script from "./default.vue?vue&type=script&lang=js&"
export * from "./default.vue?vue&type=script&lang=js&"
import style0 from "./default.vue?vue&type=style&index=0&id=5a04c39a&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5a04c39a",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppOnlineChecker: require('/usr/src/app/components/common/AppOnlineChecker.vue').default,HeaderSection: require('/usr/src/app/components/sections/HeaderSection.vue').default,MenuCategory: require('/usr/src/app/components/products/MenuCategory.vue').default,ProductPopup: require('/usr/src/app/components/sections/ProductPopup.vue').default,Metrika: require('/usr/src/app/components/Metrika.vue').default,FooterSection: require('/usr/src/app/components/sections/FooterSection.vue').default})
