//
//
//
//
//
//
//
//
//

export default {
    name: "Container",
    props: ["maxWidth"],
    computed: {
        max() {
            return this.maxWidth ? { maxWidth: `${this.maxWidth + 32}px` } : null;
        },
    },
};
