import { KITCHEN } from "~/util/types";

class Product {
    constructor(data) {
        this.data = data;
    }

    get photo() {
        return this.data.photos?.[0] || null;
    }

    get kitchen() {
        return this.data.kitchenId || KITCHEN.JAPAN;
    }

    get photoSmall() {
        return this.data.photo_small || this.photo;
    }

    get photoBig() {
        return this.data.photo_big || this.photo;
    }

    get photos() {
        return this.data.photos || [];
    }

    get id() {
        return this.data.id;
    }
}

export default Product;
