//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import SearchIcon from "~/components/icons/SearchIcon.vue";
import Container from "~/components/common/Container.vue";
import { isIndexPage } from "~/service/catalog-router/helpers";
import ParallelStickThreeIcon from "~/components/icons/ParallelStickThreeIcon.vue";

export default {
    name: "MenuCategory",
    components: {
        ParallelStickThreeIcon,
        Container,
        SearchIcon,
        Swiper,
        SwiperSlide,
    },
    data() {
        return {
            swiperPosition: {
                isBeginning: true,
                isEnd: false,
            },
            menuPosition: 0,
            isEventTouch: false,
        };
    },
    computed: {
        ...mapState("products", ["anotherMenu", "currentSort", "currentViewCategoryId"]),
        ...mapGetters("products", ["getCategories"]),
        ...mapGetters("modals", ["getSearchOpen"]),
        swiperOption() {
            return {
                slidesPerView: "auto",
                slidesOffsetBefore: 50,
                spaceBetween: 0,
                freeMode: true,
                centeredSlides: true,
                centeredSlidesBounds: true,
                pagination: { clickable: true },
                watchSlidesVisibility: true,
                breakpoints: {
                    560: {
                        slidesOffsetBefore: 0,
                    },
                },
                on: {
                    init: () => {
                        this.$nuxt.$emit("on-catalog-categories-swiper-init");
                    },
                    transitionEnd: this.slideChange,
                },
            };
        },
        inStartPosition() {
            return this.swiperPosition.isBeginning;
        },
        inEndPosition() {
            return this.swiperPosition.isEnd;
        },
        routeIsCatalog() {
            return isIndexPage(this.$router.currentRoute);
        },

        currentStyle() {
            if (this.$route.path === "/bonus_card") {
                return ["dark", "bonus"];
            }
            return null;
        },
    },
    watch: {
        currentViewCategoryId() {
            setTimeout(() => {
                this.slideChange();
            }, 300);
        },
    },

    mounted() {
        setTimeout(() => {
            this.slideChange();
        }, 300);
    },
    methods: {
        ...mapMutations("modals", ["setSearchOpen"]),
        ...mapActions("modals", ["openModal"]),
        slideChange() {
            const swiperInstance = this.$refs.mySwiper?.swiperInstance;
            if (swiperInstance) {
                if (swiperInstance.isBeginning !== this.swiperPosition.isBeginning) {
                    this.swiperPosition.isBeginning = swiperInstance.isBeginning;
                }
                if (swiperInstance.isEnd !== this.swiperPosition.isEnd) {
                    this.swiperPosition.isEnd = swiperInstance.isEnd;
                }
            }
        },
        categoryTitle(category) {
            return category.id !== this.anotherMenu
                ? category.title
                : category.title.replace(/у/gi, "<span·class='revert'>у</span>");
        },
        getDestination(category) {
            return this.$catalogRouter.getCategoryPath(category);
        },
        scrollToCategory(path) {
            this.$nuxt.$emit("scroll-to-path", path);
        },
        toggleSearch() {
            this.setSearchOpen(!this.getSearchOpen);
        },
        openCategoriesMenu() {
            this.openModal({
                modalName: "CategoriesMenu",
                modalData: { title: "Меню" },
                componentProps: { size: "xl", defaultScroll: false },
            });
        },
    },
};
